<template>
  <base-section id="theme-features">
    <base-section-heading title="Angebot" />

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :id="feature.title"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.text }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          color: 'primary',
          dark: true,
          icon: 'mdi-zodiac-libra',
          title: 'Astrologie & Psychologie',
          text: 'Sind Sie es leid mit Zahlen zu jonglieren und wollen sich mehr auf Ihr tägliches Geschäft konzentrieren? Dann unterstützen wir Sie gerne! Im Bereich der Buchhaltung bieten wir Ihnen alles aus einer Hand. Vom Einrichten einer geeigneten Softwarelösung bis zur kompletten Buchhaltung. Wir erfüllen Ihre Wünsche, abgestimmt auf Ihre persönliche Bedürfnisse! Entscheiden Sie selbst, welche Aufgaben von uns für Sie erledigt werden sollen. Wir richten Ihnen eine Buchhaltung ein, erledigen Ihre Monats-, Quartals- und Jahresabschlüsse oder buchen Ihre Kreditoren ganz wie Sie es wünschen! ​Wir bieten Ihnen auch die Möglichkeit an, über AbaWeb Treuhand direkt über das Internet auf Ihre Buchhaltung zuzugreifen.',
        },
        {
          color: 'primary',
          dark: true,
          icon: 'mdi-circle-double',
          title: 'Hypnosetherapie',
          text: 'Sie möchten die Zahlen Ihres Unternehmens in eigenen Händen halten, sind es jedoch leid das Personalwesen zu organisieren? Auch das ist kein Problem, wir übernehmen diese Arbeit für Sie. Im Rahmen unseres individuellen Betreuungsangebotes können wir die Aufgaben übernehmen, welche Sie nicht persönlich erledigen möchten. Wir erstellen für Sie die monatlichen Lohnabrechnungen inklusive Sozialversicherungsabrechnungen und allem was dazu gehört!',
        },
        {
          color: 'primary',
          dark: true,
          icon: 'mdi-account-convert',
          title: 'Kurse & Seminare',
          text: 'Im Bereich der Unternehmensberatung bieten wir verschiedene Dienstleistungen an. Vom Start-Up bis zur Nachfolgeregelung unterstützen wir Sie gerne. Besonders interessant ist unser Standort auch für Interessenten aus Deutschland. Für ausländische Unternehmen, welche in der Schweiz Fuss fassen wollen, sind wir der ideale Ansprechpartner. Durch die Zusammenarbeit mit Partnern aus Deutschland können wir Sie kompetent beraten und Sie beim Sprung in den Schweizer Markt unterstützen! Auch Firmen aus allen anderen Ländern der EU sind bei uns willkommen. Wir stehen auch als Steuervertreter zur Verfügung.',
        },
        {
          color: 'primary',
          dark: true,
          icon: 'mdi-cash',
          title: 'Preise',
          text: 'Alle Jahre wieder - die Steuererklärung! Für viele eine Qual, aber das muss nicht sein! Wir erledigen gerne Ihre Steuererklärung zu günstigen Tarifen. Ob es sich um eine Privatperson oder ein Unternehmen handelt - wir sind gerne für Sie da.',
        },
      ],
    }),
    mounted () {
      const param = new URLSearchParams(window.location.search)
      if (param.has('focus')) {
        this.$vuetify.goTo('#' + param.get('focus'))
        window.history.pushState({}, document.title, '/')
      }
    },
  }
</script>
